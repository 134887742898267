
import { Vue, Options } from 'vue-class-component';
import {
  BaseTextInput,
  BaseButton,
  BaseIcon,
  ButtonLink,
  PhoneNumberInput,
  BasePopover,
  SpecialityButton,
  BaseModal,
  BaseSelect,
  BaseSwitch,
  ActionModal,
  PopoverButton,
  DataTable,
  AvatarUpload,
  BaseCard,
  RolesSelector,
  CardHeader,
  UnitsNumberInput
} from '@/lib/components';
import {
  OrganisationRoleService,
  OrganisationUserService,
  OrganisationRoleAssignmentService,
  DomainUserService,
  ExternalIdentifierTypeService
} from '@/services/api';
import { Domain, Organisation, Role, RoleAssignment, User } from '@/models';
import { IModalAction } from '@/lib';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import { verifyInactivityTimeoutMinutes } from '@/helpers/user.helper';
import { isGMCCodeValid } from '@/lib/helpers/gmc-code.helper';
import { ExternalIdentifierType } from '@/models/external-identifier-type.model';
import { ExternalIdentifier } from '@/models/external-identifier.model';
import { RouteLocationRaw } from 'vue-router';
import { useProgressStore } from '@/stores/progress.store';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  props: {
    userId: {
      type: String,
      required: true
    },

    usersPath: {
      type: String,
      required: true
    },

    organisation: {
      type: Object,
      required: true
    }
  },
  components: {
    RolesSelector,
    CardHeader,
    BaseCard,
    DataTable,
    PopoverButton,
    BaseTextInput,
    BaseButton,
    BaseIcon,
    BaseModal,
    BaseSelect,
    ButtonLink,
    BasePopover,
    SpecialityButton,
    PhoneNumberInput,
    ActionModal,
    BaseSwitch,
    AvatarUpload,
    UnitsNumberInput
  }
})
export default class EditUserPage extends Vue {
  organisation!: Organisation | Domain;
  progressStore = useProgressStore();
  sessionStore = useSessionStore();
  notificationStore = useNotificationStore();
  user?: User;
  userId!: string;
  givenName = '';
  familyName = '';
  email = '';
  contactNumber = '';
  gmcCodeType: ExternalIdentifierType | null = null;
  gmcCode = '';
  gmcCodeError = '';
  existingGmcCode: ExternalIdentifier | null = null;
  roleAssignments: RoleAssignment[] = [];
  avatarId?: string = '';
  avatarUrl?: string = '';
  avatarLoading = false;
  saving = false;
  loading = true;
  errors: { [key: string]: Array<string> } = {};

  requires_mfa = false;
  inactivity_timeout_minutes = 30;

  roles: Role[] = [];
  availableRoleOptions: Role[] = [];
  selectedRoles: number[] = [];
  showRoleModal = false;
  showMFAModal = false;
  requests: Array<CancelTokenSource> = [];
  externalIdentifierTypesService = new ExternalIdentifierTypeService();

  get roleAssignmentService(): OrganisationRoleAssignmentService {
    return new OrganisationRoleAssignmentService(this.organisationId);
  }

  get orgUserService(): OrganisationUserService {
    return new OrganisationUserService(this.organisationId);
  }

  get orgRoleService(): OrganisationRoleService {
    return new OrganisationRoleService(this.organisationId);
  }

  get domainUserService(): DomainUserService {
    return new DomainUserService(this.organisationId);
  }

  get newRolePath(): RouteLocationRaw {
    return this.$route.meta?.isAdmin ?
      {
        name: 'domain-admin-new-role',
        params: {
          organisationId: this.organisationId
        }
      } : {
        name: 'settings-new-role'
      };
  }

  get organisationId() {
    return this.organisation.id;
  }

  get mfaModalActions(): Array<IModalAction> {
    return [
      {
        label: this.$t('platform.profile.confirm-reset') as string,
        color: 'danger',
        onClick: () => this.resetMFA()
      },
      {
        label: this.$t('platform.common.cancel') as string,
        color: 'ghost',
        onClick: () => (this.showMFAModal = false)
      }
    ];
  }

  get userUpdated(): boolean {
    return (
      !!this.user &&
      (this.user.given_name !== this.givenName ||
        this.user.family_name !== this.familyName ||
        this.user.email !== this.email ||
        this.user.phone !== this.contactNumber ||
        this.user.avatar_id !== this.avatarId ||
        this.user.avatar_url !== this.avatarUrl ||
        this.hasGmcCodeChanged())
    );
  }

  get currentUser() {
    return this.sessionStore.currentUser;
  }

  get assignmentIds() {
    return this.roleAssignments.map((a) => a.role_id);
  }

  get inactivityTimeoutString(): string {
    return this.inactivity_timeout_minutes.toString();
  }

  set inactivityTimeoutString(value: string) {
    this.inactivity_timeout_minutes = Number(value);
  }

  get permissions(): string[] {
    return this.sessionStore.permissions || [];
  }

  get userCanRemoveRoleFromUser(): boolean {
    return this.currentUser.is_admin || this.permissions.includes('user-role:revoke');
  }

  get isDomainOrSubdomain(): boolean {
    return this.organisation?.type === 'domain' || this.organisation?.type === 'subdomain';
  }

  get currentUserService() {
    return this.isDomainOrSubdomain ? this.domainUserService : this.orgUserService;
  }

  get isAnyError(): boolean {
    return !!this.gmcCodeError;
  }

  get canFetchRoles() {
    return !!(!this.isDomainOrSubdomain && (this.permissions?.includes('role:read') || this.$route.meta?.isAdmin));
  }

  created() {
    this.progressStore.startProgress();
    this.fetchData();
    if (!this.isDomainOrSubdomain) {
      this.$watch('organisationId', () => {
        this.$router.push({ name: 'settings-users' });
      });
    }
  }

  unmounted() {
    this.progressStore.removeProgress();
    if (this.requests.length) {
      this.requests.map((request) => request.cancel());
    }
  }

  updateInactivityTimeoutMinutes() {
    if (!verifyInactivityTimeoutMinutes(+this.inactivity_timeout_minutes)) {
      this.inactivity_timeout_minutes = 30;
    }
  }

  async fetchData() {
    const request = axios.CancelToken.source();
    this.requests.push(request);
    const [user, roles, assignments, gmcCodeType] = await Promise.allSettled([
      this.fetchUser(request.token),
      this.fetchRoles(request.token),
      this.fetchUserRoleAssignments(request.token),
      this.fetchGmcCodeType(request.token)
    ]);
    this.requests.splice(-1);
    if (user.status === 'fulfilled') {
      this.user = user.value;
      this.givenName = user.value.given_name;
      this.familyName = user.value.family_name;
      this.email = user.value.email;
      if (user.value.phone) {
        this.contactNumber = user.value.phone;
      }
      this.requires_mfa = user.value.requires_mfa;
      this.avatarId = user.value.avatar_id;
      this.avatarUrl = user.value.avatar_url;
      this.inactivity_timeout_minutes = user.value.inactivity_timeout_seconds / 60;
      if (!this.isDomainOrSubdomain) {
        this.roles = await this.fetchRoles(request.token);
        this.roleAssignments = await this.fetchUserRoleAssignments(request.token);
        this.setAvailableRoleOptions();
      }
      this.requests.splice(-1);
      this.progressStore.finishProgress();
    } else {
      await this.notificationStore.addErrorNotification({
        title: this.$t('platform.user.fetch-error')
      });
    }

    if (gmcCodeType.status === 'fulfilled') {
      this.gmcCodeType = gmcCodeType.value;
      this.existingGmcCode = this.getExistingGmcCode(this.gmcCodeType?.id);
      this.gmcCode = this.existingGmcCode ? this.existingGmcCode.value : '';
    }

    if (roles.status === 'fulfilled') {
      this.roles = roles.value;
    } else {
      this.notificationStore.addErrorNotification({
        title: this.$t('platform.role.fetch-error')
      });
    }
    if (assignments.status === 'fulfilled') {
      this.roleAssignments = assignments.value;
    } else {
      this.notificationStore.addErrorNotification({
        title: this.$t('platform.role.assignments-fetch-error')
      });
    }
    roles.status === 'fulfilled' && assignments.status === 'fulfilled' && user.status === 'fulfilled' ?
      this.progressStore.finishProgress() : this.progressStore.setError();
    this.setAvailableRoleOptions();
    this.loading = false;
  }

  async fetchUser(cancelToken: CancelToken): Promise<User> {
    return await this.currentUserService.fetch(this.userId, {
      cancelToken
    });
  }

  async fetchGmcCodeType(cancelToken: CancelToken) {
    const externalIdentifierTypes = (
      await this.externalIdentifierTypesService.index({
        params: {
          'filter[code]': 'GMC'
        },
        cancelToken
      })
    ).data;

    return externalIdentifierTypes[0];
  }

  async fetchRoles(cancelToken: CancelToken): Promise<Array<Role>> {
    return this.canFetchRoles ? (
      await this.orgRoleService.index({
        cancelToken
      })
    ).data : [];
  }

  async fetchUserRoleAssignments(cancelToken: CancelToken) {
    return this.canFetchRoles ? (
      await this.orgUserService.getRoles(this.userId, {
        cancelToken
      })
    ).data : [];
  }

  async createRoleAssignments() {
    try {
      this.saving = true;
      await this.orgUserService.setRoles(this.userId, this.selectedRoles);
      this.notificationStore.addSuccessNotification({
        title: this.$t('platform.role.assign-success')
      });
      const request = axios.CancelToken.source();
      this.requests.push(request);
      this.roleAssignments = await this.fetchUserRoleAssignments(request.token);
      this.requests.splice(this.requests.findIndex((req) => req.token === request.token));
    } catch (error) {
      if (!axios.isCancel(error)) {
        this.notificationStore.addErrorNotification({
          title: this.$t('platform.role.assign-error')
        });
      }
    } finally {
      this.setRoleAssignModalVisibility(false);
      this.saving = false;
    }
  }

  setRoleAssignModalVisibility(visible: boolean) {
    this.selectedRoles = this.assignmentIds;
    this.showRoleModal = visible;
    this.setAvailableRoleOptions();
  }

  async deleteRoleAssignment(roleAssignmentId: number) {
    try {
      await this.roleAssignmentService.delete(roleAssignmentId);
      this.notificationStore.addSuccessNotification({
        title: this.$t('platform.role.unassign-success')
      });
      const request = axios.CancelToken.source();
      this.requests.push(request);
      this.roleAssignments = await this.fetchUserRoleAssignments(request.token);
      this.requests.splice(this.requests.findIndex((req) => req.token === request.token));
    } catch (e) {
      if (!axios.isCancel(e)) {
        this.notificationStore.addErrorNotification({
          title: this.$t('platform.role.unassign-error')
        });
      }
    }
  }

  async requestPasswordReset() {
    try {
      this.saving = true;
      await this.currentUserService.resetPassword(this.userId);
      this.notificationStore.addSuccessNotification({
        title: this.$t('platform.user.reset-password-success')
      });
    } catch (err) {
      this.notificationStore.addErrorNotification({
        title: this.$t('platform.user.reset-password-error')
      });
    } finally {
      this.saving = false;
    }
  }

  async resetMFA() {
    try {
      await this.orgUserService.resetMFA(this.userId);
      this.notificationStore.addSuccessNotification({
        title: this.$t('platform.profile.reset-mfa-success')
      });
    } catch (err) {
      this.notificationStore.addErrorNotification({
        title: this.$t('platform.profile.reset-mfa-error')
      });
    }
  }

  get disabledNext() {
    return !this.checkForm();
  }

  checkForm() {
    return (
      this.givenName.length &&
      this.familyName.length &&
      this.email.length &&
      ((this.$refs.phone && (this.$refs.phone as PhoneNumberInput).isValid) || this.contactNumber.length === 0)
    );
  }

  async saveUser() {
    return await this.currentUserService.updateProfile(this.userId, {
      given_name: this.givenName,
      family_name: this.familyName,
      email: this.email,
      phone: this.contactNumber,
      requires_mfa: this.requires_mfa,
      avatar_id: this.avatarId,
      ...(this.gmcCodeType && this.hasGmcCodeChanged()
        ? {
          external_identifiers: [
            {
              value: this.gmcCode,
              external_identifier_type_id: this.gmcCodeType?.id
            }
          ]
        }
        : {})
    });
  }

  async updateAccountSetting() {
    this.saving = true;
    this.errors = {};
    try {
      const response = await this.saveUser();
      this.user = response.data;
      this.existingGmcCode = this.getExistingGmcCode(this.gmcCodeType?.id);
      await this.currentUserService.updateAccountSetting(this.userId, {
        requires_mfa: this.requires_mfa,
        inactivity_timeout_seconds: this.inactivity_timeout_minutes * 60
      });
      this.notificationStore.addSuccessNotification({
        title: this.$t('platform.user.update-success')
      });
    } catch (error) {
      this.errors = error.response.data.errors;
      this.notificationStore.addErrorNotification({
        title: this.$t('platform.user.update-error')
      });
    } finally {
      this.saving = false;
    }
  }

  handleAvatarLoading(value: boolean) {
    this.avatarLoading = value;
  }

  setAvailableRoleOptions() {
    if (this.userCanRemoveRoleFromUser) {
      this.availableRoleOptions = [...this.roles];
    } else {
      this.availableRoleOptions = this.roles.filter((role: Role) => !this.assignmentIds.includes(role.id));
    }
  }

  hasGmcCodeChanged() {
    return this.existingGmcCode ? this.existingGmcCode.value !== this.gmcCode : !!this.gmcCode.length;
  }

  validateGmcCode() {
    if (this.gmcCode && !isGMCCodeValid(this.gmcCode)) {
      this.gmcCodeError = this.$t('custom.uhb.user.gmc-code-error') as string;
    } else {
      this.gmcCodeError = '';
    }
  }

  getExistingGmcCode(gmcCodeTypeId = '') {
    if (!this.user) {
      return null;
    }
    return (
      this.user?.external_identifiers?.find(
        (identifier) => identifier.external_identifier_type?.id === gmcCodeTypeId
      ) || null
    );
  }
}
