export const isGMCCodeValid = (input: string): boolean => {
  if (!input) {
    return false;
  }

  if (input[0] !== 'C') {
    return false;
  }

  return /^\d{7}$/.test(input.substring(1));
};
