import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createSlots as _createSlots, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "space-y-8"
}
const _hoisted_2 = { class: "flex flex-row items-center space-x-2" }
const _hoisted_3 = { class: "pt-2 grid lg:grid-cols-2 grid-cols-1 gap-6" }
const _hoisted_4 = { class: "grid lg:grid-cols-2 grid-cols-1 gap-6" }
const _hoisted_5 = { class: "pt-6 text-lg font-semibold text-gray-900" }
const _hoisted_6 = { class: "mt-4 grid lg:grid-cols-2 grid-cols-1 gap-6" }
const _hoisted_7 = { class: "flex flex-row items-center justify-between mb-3" }
const _hoisted_8 = { class: "inline-block text-base font-medium leading-tight" }
const _hoisted_9 = { class: "h-12 items-center justify-between bg-white border border-gray-300 rounded" }
const _hoisted_10 = { class: "space-x-4" }
const _hoisted_11 = { class: "text-lg font-semibold text-gray-900" }
const _hoisted_12 = { class: "mt-4 text-lg" }
const _hoisted_13 = { class: "flex items-center mb-6 justify-between" }
const _hoisted_14 = { class: "text-lg font-semibold text-gray-900" }
const _hoisted_15 = { class: "font-medium leading-tight text-gray-900" }
const _hoisted_16 = {
  key: 1,
  class: "flex flex-col items-center justify-center pt-2 pb-6"
}
const _hoisted_17 = {
  class: "flex flex-col max-w-2xl p-8",
  style: {"width":"100vh"}
}
const _hoisted_18 = { class: "flex flex-row justify-between items-center mb-8" }
const _hoisted_19 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_20 = { class: "flex flex-row justify-end space-x-3 mt-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_PopoverButton = _resolveComponent("PopoverButton")!
  const _component_BasePopover = _resolveComponent("BasePopover")!
  const _component_CardHeader = _resolveComponent("CardHeader")!
  const _component_BaseTextInput = _resolveComponent("BaseTextInput")!
  const _component_PhoneNumberInput = _resolveComponent("PhoneNumberInput")!
  const _component_AvatarUpload = _resolveComponent("AvatarUpload")!
  const _component_UnitsNumberInput = _resolveComponent("UnitsNumberInput")!
  const _component_BaseSwitch = _resolveComponent("BaseSwitch")!
  const _component_ButtonLink = _resolveComponent("ButtonLink")!
  const _component_BaseCard = _resolveComponent("BaseCard")!
  const _component_SpecialityButton = _resolveComponent("SpecialityButton")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_RolesSelector = _resolveComponent("RolesSelector")!
  const _component_BaseModal = _resolveComponent("BaseModal")!
  const _component_ActionModal = _resolveComponent("ActionModal")!
  const _directive_allow = _resolveDirective("allow")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_BaseCard, { class: "bg-white border rounded-lg space-y-8" }, {
          default: _withCtx(() => [
            _createVNode(_component_CardHeader, {
              title: `${_ctx.givenName} ${_ctx.familyName}`,
              onBack: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push(_ctx.usersPath)))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _withDirectives((_openBlock(), _createBlock(_component_BasePopover, {
                    "data-dusk": "options-dropdown",
                    position: "left"
                  }, {
                    button: _withCtx(() => [
                      _createVNode(_component_BaseButton, {
                        color: "ghost",
                        "right-icon": "chevron-down"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('platform.common.options')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    menu: _withCtx(() => [
                      _createVNode(_component_PopoverButton, {
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.requestPasswordReset()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('platform.profile.reset-password')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })), [
                    [_directive_allow, 'user:reset-password']
                  ])
                ])
              ]),
              _: 1
            }, 8, ["title"]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_BaseTextInput, {
                modelValue: _ctx.givenName,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.givenName) = $event)),
                label: _ctx.$t('platform.profile.given_name'),
                error: _ctx.errors.given_name ? _ctx.errors.given_name[0] : null,
                "data-dusk": "edit-user-given-name-input"
              }, null, 8, ["modelValue", "label", "error"]),
              _createVNode(_component_BaseTextInput, {
                modelValue: _ctx.familyName,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.familyName) = $event)),
                label: _ctx.$t('platform.profile.family_name'),
                error: _ctx.errors.family_name ? _ctx.errors.family_name[0] : null
              }, null, 8, ["modelValue", "label", "error"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_PhoneNumberInput, {
                ref: "phone",
                modelValue: _ctx.contactNumber,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.contactNumber) = $event)),
                label: _ctx.$t('platform.login.phone-number'),
                class: "w-full",
                error: _ctx.errors.phone ? _ctx.errors.phone[0] : null
              }, null, 8, ["modelValue", "label", "error"]),
              _createVNode(_component_BaseTextInput, {
                modelValue: _ctx.email,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.email) = $event)),
                label: _ctx.$t('platform.patient.email-address'),
                type: "email",
                error: _ctx.errors.email ? _ctx.errors.email[0] : null
              }, null, 8, ["modelValue", "label", "error"]),
              (_ctx.gmcCodeType)
                ? (_openBlock(), _createBlock(_component_BaseTextInput, {
                    key: 0,
                    modelValue: _ctx.gmcCode,
                    "onUpdate:modelValue": [
                      _cache[6] || (_cache[6] = ($event: any) => ((_ctx.gmcCode) = $event)),
                      _cache[7] || (_cache[7] = ($event: any) => (_ctx.validateGmcCode()))
                    ],
                    label: _ctx.gmcCodeType.label,
                    "is-optional": "",
                    error: _ctx.gmcCodeError
                  }, null, 8, ["modelValue", "label", "error"]))
                : _createCommentVNode("", true)
            ]),
            _createVNode(_component_AvatarUpload, {
              id: _ctx.avatarId,
              "onUpdate:id": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.avatarId) = $event)),
              onLoading: _ctx.handleAvatarLoading
            }, null, 8, ["id", "onLoading"]),
            _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.$t('platform.settings.account-setting')), 1),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_UnitsNumberInput, {
                modelValue: _ctx.inactivityTimeoutString,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.inactivityTimeoutString) = $event)),
                min: 5,
                max: 60,
                "auto-correct": "",
                units: _ctx.$t('platform.common.date-time.minutes.abbreviation'),
                label: _ctx.$t('platform.profile.automatic-logout'),
                error: _ctx.errors.inactivity_timeout_seconds ? _ctx.errors.inactivity_timeout_seconds[0] : null,
                "data-dusk": "edit-user-inactivity-timeout-input",
                onBlur: _ctx.updateInactivityTimeoutMinutes
              }, _createSlots({ _: 2 }, [
                (_ctx.inactivity_timeout_minutes !== 30)
                  ? {
                      name: "actions",
                      fn: _withCtx(() => [
                        _createElementVNode("a", {
                          href: "#",
                          class: "text-primary-500 text-sm no-underline hover:underline",
                          onClick: _cache[9] || (_cache[9] = _withModifiers(($event: any) => (_ctx.inactivity_timeout_minutes = 30), ["prevent"]))
                        }, _toDisplayString(_ctx.$t('platform.common.reset-default-label')), 1)
                      ])
                    }
                  : undefined
              ]), 1032, ["modelValue", "units", "label", "error", "onBlur"]),
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('platform.profile.mfa')), 1)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_BaseSwitch, {
                    modelValue: _ctx.requires_mfa,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.requires_mfa) = $event)),
                    "wrapper-class": "p-4",
                    "label-position": "right",
                    label: _ctx.requires_mfa ? _ctx.$t('platform.profile.enabled') : _ctx.$t('platform.profile.mfa_enable')
                  }, null, 8, ["modelValue", "label"])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _withDirectives((_openBlock(), _createBlock(_component_BaseButton, {
                size: "large",
                loading: _ctx.saving,
                "data-dusk": "edit-user-account-save-btn",
                onClick: _ctx.updateAccountSetting
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.common.save-changes')), 1)
                ]),
                _: 1
              }, 8, ["loading", "onClick"])), [
                [_directive_allow, 'user:update']
              ]),
              _createVNode(_component_ButtonLink, {
                size: "large",
                color: "ghost",
                class: "ml-4",
                disabled: _ctx.saving,
                to: _ctx.usersPath
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.common.cancel')), 1)
                ]),
                _: 1
              }, 8, ["disabled", "to"])
            ])
          ]),
          _: 1
        }),
        (!_ctx.isDomainOrSubdomain)
          ? (_openBlock(), _createBlock(_component_BaseCard, { key: 0 }, {
              default: _withCtx(() => [
                _createElementVNode("h1", _hoisted_11, _toDisplayString(_ctx.$t('platform.profile.reset-mfa')), 1),
                _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('platform.profile.reset-mfa-message')), 1),
                _createVNode(_component_BaseButton, {
                  class: "mt-8",
                  size: "large",
                  color: "ghost",
                  onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.showMFAModal = true))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('platform.profile.reset-mfa-auth')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (!_ctx.isDomainOrSubdomain)
          ? (_openBlock(), _createBlock(_component_BaseCard, { key: 1 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("h2", _hoisted_14, _toDisplayString(_ctx.$t('platform.role.roles')), 1),
                  (_ctx.availableRoleOptions.length)
                    ? _withDirectives((_openBlock(), _createBlock(_component_BaseButton, {
                        key: 0,
                        color: "ghost",
                        class: "ml-4",
                        "left-icon": "add",
                        onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.setRoleAssignModalVisibility(true)))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('platform.role.assign', 1)), 1)
                        ]),
                        _: 1
                      })), [
                        [_directive_allow, 'user-role:assign']
                      ])
                    : _createCommentVNode("", true)
                ]),
                (_ctx.roles.length)
                  ? (_openBlock(), _createBlock(_component_DataTable, {
                      key: 0,
                      loading: _ctx.loading,
                      columns: [
          { name: 'name', label: '' },
          { name: 'actions', label: '', align: 'right' }
        ],
                      rows: _ctx.roleAssignments,
                      class: "w-full",
                      "show-table-header": false,
                      "no-data-message": _ctx.$t('platform.role.no-roles-assigned')
                    }, {
                      "cell.name": _withCtx(({ row }) => [
                        _createElementVNode("div", _hoisted_15, _toDisplayString(row.role.name), 1)
                      ]),
                      "cell.actions": _withCtx(({ row }) => [
                        _withDirectives((_openBlock(), _createBlock(_component_BasePopover, { position: "left" }, {
                          button: _withCtx(() => [
                            _createVNode(_component_SpecialityButton, {
                              "data-dusk": `role-${row.role.id}-popover`
                            }, null, 8, ["data-dusk"])
                          ]),
                          menu: _withCtx(() => [
                            _createVNode(_component_PopoverButton, {
                              "data-dusk": `role-${row.id}-unassign`,
                              onClick: ($event: any) => (_ctx.deleteRoleAssignment(row.id))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('platform.role.unassign')), 1)
                              ]),
                              _: 2
                            }, 1032, ["data-dusk", "onClick"])
                          ]),
                          _: 2
                        }, 1024)), [
                          [_directive_allow, 'user-role:revoke']
                        ])
                      ]),
                      _: 1
                    }, 8, ["loading", "rows", "no-data-message"]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                      _createVNode(_component_BaseIcon, {
                        name: "no-roles",
                        class: "mb-4 shadow-lg rounded-2xl",
                        stroke: false,
                        height: "64px"
                      }),
                      _createElementVNode("div", null, [
                        _createTextVNode(_toDisplayString(_ctx.$t('platform.org-unit.no-roles')), 1),
                        _withDirectives((_openBlock(), _createElementBlock("span", null, [
                          _createTextVNode(_toDisplayString(_ctx.$t('platform.org-unit.create-role')), 1)
                        ])), [
                          [_directive_allow, 'role:create']
                        ])
                      ]),
                      _withDirectives((_openBlock(), _createBlock(_component_ButtonLink, {
                        class: "mt-6",
                        to: _ctx.newRolePath,
                        color: "ghost",
                        "right-icon": "arrow-right"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('platform.role.create')), 1)
                        ]),
                        _: 1
                      }, 8, ["to"])), [
                        [_directive_allow, 'role:create']
                      ])
                    ]))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.showRoleModal)
          ? (_openBlock(), _createBlock(_component_BaseModal, {
              key: 2,
              onClose: _cache[17] || (_cache[17] = ($event: any) => (_ctx.setRoleAssignModalVisibility(false)))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("h1", _hoisted_19, _toDisplayString(_ctx.$t('platform.role.assign', 2)), 1),
                    _createElementVNode("button", {
                      type: "button",
                      class: "focus:outline-none hover:bg-gray-300 bg-gray-200 h-6 w-6 flex items-center justify-center rounded-md",
                      onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.setRoleAssignModalVisibility(false)))
                    }, [
                      _createVNode(_component_BaseIcon, {
                        name: "close",
                        class: "text-gray-900 stroke-1.5",
                        height: "8px"
                      })
                    ])
                  ]),
                  _createVNode(_component_RolesSelector, {
                    modelValue: _ctx.selectedRoles,
                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.selectedRoles) = $event)),
                    roles: _ctx.availableRoleOptions
                  }, null, 8, ["modelValue", "roles"]),
                  _createElementVNode("div", _hoisted_20, [
                    _createVNode(_component_BaseButton, {
                      color: "ghost",
                      disabled: _ctx.saving,
                      class: "ml-4",
                      onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.setRoleAssignModalVisibility(false)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('platform.common.cancel')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled"]),
                    _createVNode(_component_BaseButton, {
                      loading: _ctx.saving,
                      onClick: _ctx.createRoleAssignments
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('platform.common.save-changes')), 1)
                      ]),
                      _: 1
                    }, 8, ["loading", "onClick"])
                  ])
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.showMFAModal)
          ? (_openBlock(), _createBlock(_component_ActionModal, {
              key: 3,
              title: _ctx.$t('platform.profile.reset-mfa'),
              body: _ctx.$t('platform.profile.reset-mfa-action'),
              actions: _ctx.mfaModalActions,
              onClose: _cache[18] || (_cache[18] = ($event: any) => (_ctx.showMFAModal = false))
            }, null, 8, ["title", "body", "actions"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}